.ply-btn {width: 40px; display: flex; justify-content: flex-end;}
.table-row {margin-bottom:10px; background: #800022; display: flex; width: 100%;    justify-content: space-between; padding: 20px; border-radius: 10px; align-items:center;}
.table-cnt { width: calc(100% - 40px); padding-right:10px;}
.td-ctn {display: flex; margin: 0 -10px;}
.td-ctn p {padding: 0 10px; font-size: 16px;  color: #00D8C3; font-weight:500;}
.mobile-table{display: none;}
.ply-btn button {background: #4D0014;border: 1px solid #FFF5F8; border-radius: 100%; width: 35px;height: 35px;color: #FFF5F8;}
.mobile-table .table-row:nth-child(even) { background: #1A0007;}
.content-bnr { position: absolute; top: 80px; left: 10%;}
.content-bnr h2 {font-size:65px; color: #FFCCDA;}
.content-bnr h2 strong {font-weight: 600;}

.smash-pop-box h5 {font-weight: 100; margin-bottom:20px;  font-weight: 100;  margin-bottom: 20px;}
.smash-pop-box p {color: #FFF5F8;}
.modal-title {color: #FF0144; font-size: 32px;}
.ply-gm h4 {font-size: 22px; font-weight: 100; margin-top: 10px; margin-bottom:10px;}

.smash-pop-box-row {display: flex;justify-content: space-between; margin: 0 -15px;}
.smash-pop-box {padding: 0 15px; text-align: center;}
.btn-row-draft {text-align: right;}
.prize-box table th {position: sticky;height: 45px;top: 0px;background: #0a0204;}
.prize-box .table-main th:first-child {text-align: center;}
.prize-box .table-main td:first-child {text-align: center;}
.add-new button {margin-left: 15px;}
.add-new {margin-bottom: 30px; display: flex; justify-content: flex-end;}
.add-tr .modal-dialog {max-width: 700px; width: 100%;}
.form-label {  margin-bottom: 0.5rem; color: #FF668E;  font-size: 14px;     margin-left: 0;}
.add-tr .form-control {color: #FFF5F8 !important;
    padding-right: 25px;
    background: transparent;
    box-shadow: none !important;
    border: 0;
    border-bottom: 1px solid #FF668E;
    border-radius: 0;
    padding-left: 0;}
.add-tr .form-control::placeholder {  color: #FFF5F8 !important}

.add-tr-btn button{margin-right:15px;}
.m-tble {width: 100%;  overflow-y: auto;}
.m-tble table td:last-child { width: 143px !important; display: inline-block;}

@media screen and (max-width: 1480px) {
.content-bnr h2 {font-size: 45px;}
.m-tble table {width: 1800px;}       
}

@media screen and (max-width: 1199px){
.scroll-table {overflow-x: auto;}
.table-main.scroll-table table {width: 1200px;}    
    
}

@media screen and (max-width: 767px){
.mobile-table{display: block;}


}

.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    left: 0;
}
.paginationButton{
    font-size: 30px;
    margin: 10px;
    padding: 2px 25px;
    color: white;
    background-color: #790020;
}