footer .footer-links {display: flex;align-items: center; justify-content: center;}
footer .footer-links button {background: transparent;border: 0;color: #FFF5F8;}
.footer-links img {padding: 0 25px;}
footer {background: #32000D;padding: 20px 0; display: flex; align-items: center; margin-top:40px;}

@media screen and (max-width: 767px){
.footer-links img {padding: 0 10px; height:20px; }
footer .footer-links button{font-size:14px;}
.desktop-view{display: none;}

}


@media screen and (max-width:480px){
    .footer-links img {padding: 0 8px; height:15px; }
    footer .footer-links button{font-size:12px;}
    
    
    }