.bttn:hover{background-color: darkturquoise;color:white;}
p {
    margin-top: 20px!important;
    margin-bottom: 20px!important;
}
.adventure-popup .user-card .col-card {
    max-width: 25%;
    width: 100%;
  }

.adventure-popup .ply-gm h4 {
    font-size: 18px;
    font-weight: 100;
    margin-top: 10px;
    margin-bottom: 10px;
    height: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.adventure-popup .card-box p {
    margin: 5px 0 !important;
    height: auto;
  }

.select-callsheet-spacing {
    display: flex;
    align-items: baseline;
    justify-content: end;
  }

  .ent-row {
    margin: 0 -5px;
  }

  .ent-row a {
    display: inline-block;
    border: 1px solid #993434;
    border-radius: 3px;
    margin: 0 5px 10px;
    color: #fff;
    padding: 8px 15px;
    text-decoration: navajowhite;
  }

  .search-btn{
    height: 5;
  }

  .adventure-popup .card-box p{
    font-size: 16px;
  }

  