

.Banner {position:sticky;top: -60px; z-index: 9; height: 355px; width: 100%; background-size: cover !important;background-repeat: no-repeat;background-position: center center !important;border-radius: 20px;margin-bottom: 40px;}
.search-btn input {background: #800022 !important;}
.search-btn {background: #800022;}
.banner-content h1 {font-size: 65px;font-weight: 100; text-align:left; margin-bottom:20px; color:#FFCCDA;}
.banner-content {width: 550px;position: absolute; left: 80px; top: 50%; transform: translateY(-50%);}
.search-btn input::placeholder {color:#FFF5F8 !important;}
.search-btn span i {color: #00D8C3;}
.support-btn-section h3 {font-size: 44px;text-align: center;padding:70px 0 30px; color:#FFCCDA}
.support-btn-section {text-align: center;}
.term-cnd { margin-bottom: 50px;}
.term-cnd h3 { margin-bottom: 20px; font-size: 28px;}

@media screen and (max-width: 1199px){

.banner-content h1 {font-size: 38px;}
.Banner{position: static;}
.term-cnd {margin-bottom: 20px;}
.terms-conditons p {font-size: 16px;}
.term-cnd h3 { margin-bottom: 12px;  font-size: 24px;}
}

@media screen and (max-width: 1024px){ 
.terms-conditons p {font-size: 16px;}
.term-cnd h3 { margin-bottom: 12px;  font-size: 24px;}
.Banner { margin-top: 15px;  height: 290px;}
}

@media screen and (max-width: 767px){ 
.term-cnd h3 { margin-bottom: 12px;  font-size: 20px;}
.banner-content {width:80%; left: 0;  right: 0;  margin: 0 auto;}
.banner-content h1 { font-size: 30px;}
}