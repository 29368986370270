html {
    scroll-behavior: smooth;
}

body {
    font-weight: 300 !important;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    background: #0A0204;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    transition: 0.5s all;
}

img {
    height: auto;
    max-width: 100%;
}

p,
ul,
span {
    margin: 0;
    padding: 0;
    color: #FFF5F8;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    color: #20FCE5;
}

* {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

h2 {
    font-size: 32px;
    font-weight: 100;
}

h3 {
    font-size: 28px;
    font-weight: 100;
}

button {
    outline: none !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pink-d {
    background: #FF0144;
}

.prpl {
    background: #BB86FC;
}

.green {
    background: #209A1D;
}

.pink-text {
    color: #FF668E !important;
}

.btn-check:active+.btn-success,
.btn-check:checked+.btn-success,
.btn-success.active,
.btn-success:active,
.show>.btn-success.dropdown-toggle {
    background: transparent !important;
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
    color: #fff;
    background-color: transparent !important;
    border-color: none !important;
    box-shadow: none !important;
}

.p-r-10 {
    padding-right: 10px;
}

.b-t {
    border-top: 0 !important;
}

.blue-btn {
    background: #00D8C3;
    min-height: 50px;
    border-radius: 8px;
    border: 0;
    color: #F6F7F9; 
    padding: 5px 15px;
}

.red-btn {
    background: #ad1126;
    height: 50px;
    border-radius: 8px;
    border: 0;
    color: #F6F7F9;
    padding: 0 15px;
}

.blu-border-btn {
    background: transparent;
    min-height: 50px;
    border-radius: 8px;
    border: 1px solid #00D8C3;
    color: #00D8C3;
    padding: 5px 15px;
}

.white-border-btn {
    background: transparent !important;
    height: 50px;
    border-radius: 8px;
    border: 1px solid #F6F7F9 !important;
    color: #F6F7F9 !important;
    padding: 0 15px;
}

.page-heading {
    margin: 40px 0;
}

.dark-button {
    background: #4D0014;
    height: 50px;
    border-radius: 8px;
    border: 0;
    color: #FFCCDA;
    padding: 0 15px;
}

.pink-btn {
    background: #F90044;
    height: 50px;
    border-radius: 8px;
    border: 0;
    color: #F6F7F9;
    padding: 0 15px;
}

.pink-btn:hover {
    background: #20FCE5;
    color: #F6F7F9;
}

.btn-primary:hover {
    background-color: #F90044 !important;
    border-color: transparent !important;
}

.blue-btn:hover {
    background-color: #F90044 !important;
    border-color: transparent !important;
}

.border-nav {
    border-top: 1px solid #4D0014;
    padding-top: 15px;
    min-height: calc(100vh - 166px);
}

    

.banner {
    width: 100%;
    display: inline-block;
    margin-bottom: 50px;
    position: relative;
}

.banner .banner-img {
    margin-top: -38px;
}

.banner .page-ntf {
    position: absolute;
    width: auto;
    left: 28%;
    top: 20px;
}

.table-main th {
    color: #20FCE5;
    font-weight: 300;
    font-size: 18px;
    border: 0;
    padding: 15px 10px;
}

.table-main td {
    border: 0;
    font-size: 18px;
    color: #FFCCDA !important;
    padding: 15px 10px;
    text-align: center;
    vertical-align: baseline;
}

.table-main .table-striped>tbody>tr:nth-of-type(odd) {
    background: #800022;
    color: #FFCCDA !important;
    vertical-align: middle;
}

.table-main tbody tr:hover {
    background: #4D0014;
}

.table-main .table-striped>tbody>tr:nth-of-type(odd):hover {
    background: #4D0014;
}

.table-main td:first-child {
    text-align: left;
}

.table-main th {
    text-align: center;
}

.table-main th:first-child {
    text-align: left;
}

.table-main td button {
    min-height: 35px;
    border: 1px solid #FFF5F8;
    border-radius: 5px;
    background: #800022;
    color: #FFF5F8;
    padding: 5px 15px;
    text-transform: uppercase;
}

.table-main td button:hover {
    background: #FFF5F8;
    color: #6f001d;
}

.table-main table tr:hover button {
    background: #FFF5F8 !important;
    color: #6f001d !important;
}

.scroll-table {
    height: 600px;
    overflow-y: auto; width:100%;
}

.btn-row {
    display: flex;
    justify-content: end;
}

.btn-row button {
    background: transparent;
    border-color: #FFF5F8;
}

.search-col .btn-group.button-tb { margin-top: 0;}
.callsheet-btn-row { margin: 0 0 20px;}

.smash-box.live-content .contest-row {  width: 100%;  justify-content: space-between;}
.smash-box.live-content .contest-row > div {  width: 100%; }
.drp-calendar.left.single {  background: white;}
label.form-check-label {
    color: #FF668E;
}
.contest-table-lv table {  width: 100%;}

.scroll-table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #4D0014;
}

.scroll-table::-webkit-scrollbar {
    width: 10px;
    background-color: #4D0014;
}

.scroll-table::-webkit-scrollbar-thumb {
    background-color: #800022;
    border-radius: 5px;
}

.table-main table tr th span {
    padding: 5px 20px;
    border-radius: 20px;
    position: relative;
    display: inline-block;
    color: #20FCE5;
}

.table-main table tr th span:hover {
    background: #4D0014;
}

.table-main table tr th span i {
    font-size: 14px;
    display: none;
}

.table-main table tr th span:hover i {
    display: inline;
    position: absolute;
    right: 13px;
    top: 33%;
}

.table-main .table-striped>tbody>tr {
    background: #1A0007;
}

.table-main td a {
    color: #FFCCDA;
    text-decoration: none;
}

tr.green-bg {
    background: #027E71 !important;
}

tr.green-bg button {
    background: #FFF5F8 !important;
    color: #027e71 !important;
}

.page-ntf {
    margin-bottom: 40px;
    width: 100%;
    display: inline-block;
    position: relative;
}

.button-tb a img {
    margin-right: 10px;
}

.page-ntf button {
    background: transparent;
    border: 0;
    color: #FFF5F8;
    padding: 0 20px 10px 0;
    position: relative;
}

.page-ntf button.active {
    color: #00d8c3;
    position: relative;
}

.page-ntf button:hover:after {
    content: '';
    border-bottom: 2px solid #00d8c3;
}

.page-ntf button:after {
    content: '';
    position: absolute;
    width: 20%;
    border-bottom: 2px solid transparent;
    left: 0;
    bottom: 0;
}

.page-ntf button.active:after {
    content: '';
    position: absolute;
    width: 20%;
    border-bottom: 2px solid #CC0136;
    left: 0;
    bottom: 0;
}

.page-ntf button .ntf-no {
    width: 20px;
    height: 20px;
    background: #CC0136;
    display: inline-block;
    border-radius: 100%;
    text-align: center;
    line-height: 20px;
    color: #FFF5F8;
    font-size: 14px;
    margin-left: 5px;
}

.upcoming-contest-row {
    display: flex;
    flex-wrap: wrap;
}

.heading-icn { color: #FFF5F8;}

.upcoming-contest-row h2 {
    flex: 1;
    padding-right: 20px;
}

.heading-upcoming {
    flex: 1 1;
    display: flex;
    align-items: center;
}

.heading-upcoming img {
    margin-right: 14px;
}

.up-contest-content {
    display: flex;
    justify-content: space-between;
    flex: 2;
}

.up-cont-box p {
    font-size: 16px;
    color: #03DAC6;
    margin-bottom: 15px;
}

.up-cont-box {
    text-align: center;
    padding: 0 10px;
}

.smash-box {
    background: #1A0007;
    border-radius: 10px;
    margin: 40px 0;
    padding: 15px;
}

.c-smash-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.smash-content {
    display: flex;
    justify-content: space-between;
    flex: 2;
}

.smash-heading {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
}

.smash-heading h3 {
    width: 100%;
    margin-bottom: 15px;
}

.smash-cnt-box {
    text-align: center;
}

.smash-cnt-box p {
    font-size: 16px;
    color: #03DAC6;
    margin-bottom: 15px;
}

.card-box {
    background: #800022;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    flex-wrap: wrap;
  }

.card-box:hover {
    box-shadow: 0px 0px 25px 12px #5a0018 inset;
}

.smash-card-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.col-card {
    padding: 5px;
    max-width: 10%;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.card-images {
    width: 70px;
    height: 70px;
    display: inline-block;
    border-radius: 100%;
    border: 2px solid #FF99B4;
    padding: 3px;
}

.card-images img {
    height: 60px;
    width: 60px;
    border-radius: 100%;
}
 
.card-name {
    font-size: 18px;
    color: #FFF5F8;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
    height: 45px;
    margin-top: 20px;
}

.card-images p {  color: #FF668E;   font-size: 16px; height:40px;}
.card-rate span { color: #20FCE5 !important;  margin-top: 10px;}
.card-box .heading-icn {   margin: 5px 0;}
.content-card p {    color: #FF668E; overflow: hidden;  display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; height: 55px;}
.mycontests .table-main {   margin-bottom: 40px;}

.content-card a { color: #FF668E;  text-decoration: none; overflow: hidden;  display: -webkit-box;  -webkit-line-clamp: 2;-webkit-box-orient: vertical;}
/* =======
.card-name {
    font-size: 18px;
    color: #FFF5F8;
    text-align: center;
    margin-bottom: 10px; cursor: pointer;
}

.card-images p {
    color: #FF668E;
    font-size: 16px;
}

.card-rate {
    color: #20FCE5 !important;
    margin-top: 10px;
}

.card-rate span {
    color: #03DAC6;
    font-size: 18px;
    font-weight: 400;
}

.card-box {
    background: #800022;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
}

.card-box .heading-icn {
    margin: 5px 0;
}


.content-card p {
    color: #FF668E;
    font-size: 14px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.mycontests .table-main {
    margin-bottom: 40px;
}

.content-card a {
    color: #FF668E;
    text-decoration: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.content-card {
    width: 100%;
} */

.card-name a {
    font-size: 18px !important;
    color: #FFF5F8 !important;
    text-align: center !important;
    text-decoration: none !important;
}

.score .box-row {
    display: flex;
    justify-content: space-between;
}

.score .left-box {
    width: 33%;
    background: #1A0007;
    padding: 100px 50px;
    border-radius: 10px;
}

.score .right-box {
    width: calc(67% - 15px);
    background: #1A0007;
    padding: 40px 40px;
    border-radius: 10px;
}

.box-office-content h3 {
    color: #FFCCDA;
    font-weight: 100;
    text-align: center;
    margin-bottom: 100px;
    font-size: 60px;
}

.content-card {
    width: 100%;
  }

.bx-select-row {
    color: #FFF5F8;
    font-size: 20px;
    margin-bottom: 20px;
    display: flex !important;
    align-items: center;
}

.line-red {
    background: #FF0144;
    width: 25px;
    height: 4px;
    border-radius: 5px;
}

label {
    margin: 0 20px 0 10px;
}

.bx-select-row span {
    width: 25px;
    height: 4px;
    border-radius: 5px;
}


 {
    background: #FF0144;
    width: 25px;
    height: 4px;
    border-radius: 5px;
}  

.title-row {
    display: flex;
    align-items: center;
}

.title-scr {
    width: 480px;
    text-align: right;
    padding-right: 25px;
}

.scrore-pg {
    width: calc(100% - 480px);
}

.scroll-x{
    max-height: 75vh;
overflow-x: auto; 
}

table.admin-players-table td:last-child button {
    margin-left: 5px;
    margin-right: 0;
    text-align: right !important;
    margin-bottom: 9px;
}

.import-button {
    display: flex;
    justify-content: space-between;
}

.import-button .blu-border-btn {
    width: 145px;
}

.import-button .callsheet-submit {
    width: calc(100% - 155px) !important;
}

.import-button button.blue-btn {
    width: 100% !important;
}

.clear-callsheet a {
    color: #FF668E !important;
    font-size: 16px;
}

.clear-callsheet {
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.title-row.heading-scr-bar {
    margin-bottom: 40px;
}

.title-row.heading-scr-bar h4 {
    font-size: 24px;
    font-weight: 100;
}

.react-sweet-progress-symbol {
    color: #FFF5F8 !important;
    opacity: 0;
    transition: 0.3s;
}




.bartop .react-sweet-progress-line-inner {
     background: #BB86FC !important;
    min-height: 5px !important;
}

.barmiddle .react-sweet-progress-line-inner {
     background: #FF0144 !important;
    min-height: 5px !important;
}

.barbottom .react-sweet-progress-line-inner {
     background: #209A1D !important;
    min-height: 5px !important;
}
.title-row {
    padding:10px 0;
}

.right-box .title-row :hover .react-sweet-progress-symbol{opacity: 1;}

.react-sweet-progress-line-inner-status-active:before {
    display: none;
}

.react-sweet-progress-line {
    background: transparent !important; 
    
}



/* .right-box :hover .react-sweet-progress-symbol {
    display: flex !important;
    position: relative;
    margin: 0 0 0;
    position: absolute;
    right: 0;
} */

.react-sweet-progress{position: relative;justify-content:space-between !important;}
.react-sweet-progress-symbol{width: auto  !important;}

.pr-bt-row {
    display: flex;
    width: 100%;
    justify-content: end;
}

.pr-x {
    width: calc(100% - 450px);
    justify-content: space-between;
    display: flex;
}

.row-comp .react-sweet-progress-symbol {
    display: none;
}

.comp-score-box {
    background: #1A0007;
    padding: 40px 40px;
    border-radius: 10px;
    margin-top: 15px;
    position: relative;
}

.row-comp {
    display: flex;
    align-items: center;
    justify-content: center;
}

.middle-box {
    width: 350px;
    text-align: center;
}

.comp-box {
    width: 20%;
}

.comp-box.prpl-pg .react-sweet-progress-line {
    flex-direction: row-reverse;
    display: flex;
}

.middle-box p {
    border-left: 1px solid #4d0014;
    border-right: 1px solid #4d0014;
    padding: 5px 5px 15px;
}

.comp-score-box .react-sweet-progress-line-inner {
    border-radius: 0;
}

.comp-score-box .prpl-pg .react-sweet-progress-line-inner {
    background: #BB86FC !important;
}

.comp-score-box .green-pg .react-sweet-progress-line-inner {
    background: #209A1D !important;
}

.left-graph {
    width: 195px;
    border-radius: 5px 5px 0;
}

.left-graph {
    padding: 8px 0 0 !important;
    ;
    width: 195px;
    border-radius: 5px 5px 0;
}

.comp-score-box .dropdown-menu.show {
    width: 100% !important;
    display: block;
    background: #33000d;
    border-radius: 0 0 5px 5px !important;
    border-top: 0;
}

.comp-score-box button {
    font-size: 14px;
    border: 0 !important;
    width: 100%;
    background: transparent;
    color: #20FCE5 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.graph-detail {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    padding: 0 30px;
    z-index: 9;
}

.graph-point {
    font-size: 28px;
    text-align: center;
    color: #FFCCDA;
    display: none;
}

.comp-score-box:hover .graph-point {
    display: block;
}

.btn-success:active,
.show>.btn-success.dropdown-toggle {
    background: #33000D !important;
}

.form-group input[type=checkbox]+label {
display: flex;
cursor: pointer;
align-items: center;
justify-content: center;
}

.form-group input[type=checkbox] {
    display: none;
}

.form-group input[type=checkbox]+label:before {
      border: 0.1em solid #FFF5F8;
    display: flex;
    width: 1em;
    height: 1em;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
    align-items: center;
    margin-right: 28px;
    position: absolute;
    top: 5px;
    left: 0; 
}

.box-select .form-group input:checked + label:after {
    content: '';

    top: 9px;
    left: 9px;
    width: 6px;
    height: 12px;
    border: solid #1A0007;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}


.form-group input[type=checkbox]+label:active:before {
    transform: scale(0);
}

.form-group input[type=checkbox]:checked+label:before {
    background-color: #20FCE5;
    border-color: #20FCE5;
    color: #33000D;
}

.form-group input[type=checkbox]:disabled+label:before {
    transform: scale(1);
    border-color: #aaa;
}

.form-group input[type=checkbox]:checked:disabled+label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
}

.button-tb {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-top: 20px;
}

.button-tb a:first-child {
    border-radius: 5px 0 0 5px;
}

.button-tb a:last-child {
    border-radius: 0 5px 5px 0;
}

.button-tb a.active {
    background: #03DAC6;
    color: #fff;
    border-color: #03DAC6;
}

.button-tb a {
    border:1px solid #CC0136;
    height:50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: #03DAC6;
    text-decoration: none;
    
}

.button-tb a:last-child img {
    margin-right: 8px;
    height: 22px;
}

.button-tb a:hover {
    background: #1A0007;
}

.button-tb a.active img {
    filter: brightness(0) invert(1);
}

.button-tb label:nth-child(2) {
    border-radius: 5px 0 0 5px;
    border-right: 0;
}

.button-tb label:last-child {
    border-radius: 0 5px 5px 0;
}

.button-tb .active {
    background: #03DAC6;
    color: #fff;
    border-color: #03DAC6;
}

.btn-check:checked+.btn-outline-primary {
    background: #03DAC6;
    color: #fff;
    border-color: #03DAC6;
}

.button-tb label {
    border: 1px solid #CC0136;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 5px 18px;
    color: #03DAC6;
    text-decoration: none;
    margin: 0;
      margin-left: 0px;
    flex-wrap: wrap;
  }

.button-tb label:last-child img {
    margin-right: 8px;
    height: 22px;
}

.button-tb label:hover {
    background: #1A0007;
}

.button-tb label.active img {
    filter: brightness(0) invert(1);
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
    margin-left: 0;
}

.btn-check:checked+.btn-outline-primary img {
    filter: brightness(0) invert(1);
}

.button-tb label img {
    margin-right: 8px;
    height: 18px;
}

.draft-call-sheet {
    text-align: center;
    margin-top: 13%;
  }

.search-btn input {
    background: #4D0014;
    border: 0;
    box-shadow: none;
    flex: inherit !important;
    width: calc(100% - 44px) !important;
    height: 50px;
    color: #FFF5F8
}

.search-btn span {
    background: #4d0014;
    color: #20FCE5;
    border: 0;
    box-shadow: none;
}

.search-btn {
    background: #4d0014;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.search-btn span {
    background: #3e0011;
    color: #20FCE5ff;
    border: 0;
    box-shadow: none;
    border-radius: 100% !important;
    width: 40px;
    height: 40px;
    display: flex;
    margin-left: 0 !important;
    align-items: center;
    justify-content: center;
}

.search-btn .form-control:focus {
    background: #4D0014;
    outline: none;
    box-shadow: none;
}

.search-btn.input-group input::placeholder {
    color: #800022;
}

.class-52 {
    width: 100%;
    display: flex;
    margin-bottom: 15px;
  }

  .flx-rp {
    flex-wrap: wrap;
  }

  .flx-rp .csv-reader-input {
    margin: 15px 0;
    color: #fff;
  }

  .class-54 {
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 58%;
    margin: 10px 0 0;
  }

.modal-content {
    background-color: #0A0204;
    border-radius: 15px;
}

.modal-header {
    border-bottom: 0;
    padding: 15px 30px;
}

.create-callsheet {
    border-top: 1px solid #4D0014;
    margin: 20px -30px 0;
    padding: 20px 30px 0;
}

.modal-body {
    padding: 0 30px 30px 30px;
}

.prize-box h4 {
    margin: 50px 0 20px;
    font-size: 22px;
    font-weight: 300;
}

.prize-box .scroll-table {
    max-height: 320px;
}

.tour-popup .modal-body {
    padding: 0 30px 30px 30px;
    max-height: 530px;
    overflow-y: auto;
  }

  .ent-row a:hover {
    background: #4D0014;
}

.scroll-crd{
    overflow-y: auto;
    max-height: 360px;
}

.tour-height .modal-body{
    height:300px !important ;
    overflow-y:auto !important;
}

.class-5{
    border-top: 1px solid #4D0014;
    margin: 20px 20px 8px 0 !important;
padding: 20px 30px 0;
}

.upcoming-contest-row.class-51 .callsheet-img-row {
    justify-content: flex-start;
}
  
.popup-close {
    background: #FF0144;
    border-radius: 100%;
    position: absolute;
    right: -10px;
    top: -15px;
    border: 0;
    z-index: 9;
}

.modal-dialog {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%) !important;
}

.delete-btn {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    border: 0;
    background: #4D0014;
    color: #20FCE5;
}

.delete-btn:hover {
    background: #4D0014
}

.form-group label {
    position: relative;
    padding-left: 30px;
}

.rt-critics {
    width: 200px;
}

.modal-backdrop {  
background-color: #8d6871c2 !important;
}

.google-critics {
    width: 200px;
}

.popup-content {
    border-top: 1px solid #800022;
    margin-left: -30px;
    margin-right: -30px;
    padding: 30px 30px 20px;
}

.pop-row {
    display: flex;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 30px;
}

.pop-row div {
    padding: 0 15px;
    text-align: center;
}

.popup-content h2 {
    font-size: 28px;
    color: #FFCCDA;
    margin-bottom: 40px;
}

.movie-type-box h5 {
    font-weight: 300;
    margin-bottom: 15px;
}

.modal-title {
    font-size: 32px;
    color: #FF0144;
    font-weight: 300;
}

.pupup-btn-row a {
    color: #03DAC6;
    margin-right: 10px;
    text-decoration: none;
}

.pupup-btn-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.delete-swag-popup h2 {
    color: #20FCE5;
    margin-bottom: 0;
    margin-top: 40px;
}

.popup-content.delete-swag-popup .p-text {
    font-size: 28px;
}

.delete-success {
    text-align: center;
}

.delete-success img {
    height: 100px;
    margin-bottom: 20px;
}

.delete-popup .modal-dialog {
    max-width: 800px;
}

.confirm-account h1 {
    margin-bottom: 40px !important;
}

.popup-table td:first-child {
    text-align: center;
}

.popup-table th:first-child {
    text-align: center;
}

.popup-card .col-card {
    max-width: 33.3%;
}

.popup-table {
    max-height: 425px;
}

.popup-heading {
    margin-bottom: 0;
}

.popup-table table th {
    position: sticky;
    top: 0;
    height: 40px;
    background: #050102;
}

.popup-heading h3 {
    margin-bottom: 15px;
    font-size: 22px;
}

.live-card {
    max-height: 350px;
}

.red-cls-btn {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    padding: 0;
    background: red;
    border: 0;
    color: #110206;
}

.view-cls {margin-top: 40px;}

.cnt-bg {background: #1A0007; padding: 15px; border-radius: 10px;}
.table-main td:first-child .form-group label {  position: static;  display: inline-block}
.table-main td:first-child .form-group {  position: relative;}

.delete-swg .popup-heading {
    margin-bottom: 0;
}

.delete-swg .modal-body {
    padding-top: 0;
}

.user-hover {
    position: relative;
    display: flex;
    justify-content: center
}

/* .user-hover{
    display: none;
} */

.user-hover span{
    display: none;
}


.user-hover:hover span {
    padding: 5px;
    display: block;
    position: absolute;
    background: #02B09F;
    border-radius: 3px;
    white-space: nowrap;
    font-size: 14px;
    bottom: -34px;
    color: #FFF5F8;
    margin-left: 20px;
}

.user-hover span:before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    background: #02b09f;
    top: -4px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
}


.left-side-nav {
    width: 170px;
    flex: 170px 0 !important;
    border-right:1px solid #4D0014; padding-top:20px;
}

.react-sweet-progress-line {
    width: 65% !important;}

.react-sweet-progress { position: relative;   }
.score-history {  margin: 20px 0 0 !important; }
.popup-profile {padding: 0 30px;}
.score-date-tble .table-main {height: 460px !important;}
.right-advent .scroll-table {  height: 600px;}
.banner-adv2 {width: 450px;}

.scroll-table .table>thead {position: -webkit-sticky; position: sticky;  top: 0;  z-index: 1; background:#0A0204;}

@media screen and (max-width: 1480px) {



    h2{ font-size:26px; }
    .contest-table table {width: 1200px; }
    
    .card-name { font-size: 16px;    margin-bottom: 5px;    }

    .modal-title {
        color: #FF0144;
        font-size: 24px;
    }

    .callsheet-img-row {justify-content: center; }

    .col-card {
        max-width: 20%;
    }
    .upcoming-contest-row .heading-upcoming h2 {
        font-size: 28px;
        flex: inherit;
    }
    .form-group input[type=checkbox]+label {
        font-size: 16px;
    }
    .title-scr {
        width: 325px;
    }

  
    .scrore-pg {
        width: calc(100% - 325px);
    }
    .pr-x {
        width: calc(100% - 390px);
    }
    .pr-x span {
        font-size: 12px;
    }
    .middle-box p {
        font-size: 14px;
    }
    .graph-detail button {
        font-size: 14px !important;
    }
    .pbar-no p {
        font-size: 20px !important;
    }
    .score .right-box {
        padding: 20px;
    }
    .score .left-box {
        padding: 50px 20px;
    }
    .title-scr p {
        font-size: 14px;
    }

    .react-sweet-progress-symbol {font-size: 14px; }

}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 1024px) {
    .col-card {
        max-width: 33.3%;
    }
    .border-nav {
        margin-top: 15px;
    }
    .col-card {
        max-width: 25%;
    }
    h2 {
        font-size: 24px;
    }
    .cls-sheet-tbl {
        width: 100%;
        overflow-x: auto;
    }
    .cls-sheet-tbl table {
        width: 1200px;
    }
    .modal-title {
        font-size: 22px;
    }
    .p-text {
        font-size: 16px !important;
    }
    .banner-adv2 {width:380px;}

    .delete-swag-popup h2 {
        margin-bottom: 0;
        margin-top: 18px;
        margin-bottom: 20px;
        font-size: 22px;
    }
    .pupup-btn-row {
        margin-top: 20px;
    }
    .cnf-contest .popup-content h2 {
        margin-bottom: 15px;
        font-size: 22px;
    }
    .movie-type-box h5 {
        font-size: 14px;
        margin-bottom: 5px;
    }
    .movie-type-box p {
        font-size: 14px;
    }
    .cnf-contest .movie-type-box {
        width: 50%;
        margin-bottom: 20px;
    }
    .cnf-contest .pop-row {
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
    .live-cnt-popup .col-card {
        max-width: 50%;
    }
}

@media screen and (max-width: 991px) {
    .col-card {
        max-width: 33.3% !important;
    }
    .upcoming-contest-row h2 {
        flex: 100%;
        text-align: center;
        margin-bottom: 25px;
    }
    h3 {
        font-size: 20px;
    }
    .btn-sm-100 {
        width: 100% !important;
    }
    .btn-sm-100 button {
        width: 100%;
    }
    .up-cont-box {
        margin-bottom: 25px;
    }
    .smash-heading {
        flex: 100%;
        justify-content: center;
        text-align: center;
        margin-bottom: 25px;
    }
    .smash-content {
        flex: 100%;
        flex-wrap: wrap;
    }
    .smash-cnt-box {
        text-align: center;
        width: 50%;
        margin-bottom: 20px;
        justify-content: center;
    }
    .smash-box {
        margin: 20px 0;
    }
    .up-contest-content {
        flex-wrap: wrap;
    }
    .score .left-box {
        width: 100%;
    }
    .score .box-row {
        flex-wrap: wrap;
    }
    .box-office-content h3 {
        color: #FFCCDA;
        font-weight: 100;
        text-align: left;
        margin-bottom: 30px;
        font-size: 40px;
    }
    .score .right-box {
        margin-top: 15px;
        width: 100%;
    }
    .comp-score-box {
        padding: 20px;
    }
}

@media screen and (max-width: 767px) {
    .col-card { max-width:50% !important; }
    .content-card p {
        color: #FF668E;
        font-size: 14px;
    }

    .banner-adv2 {width: 100%;}

    .content-card a{font-size:14px;}
    .up-cont-box {
        margin-bottom: 25px;
        width: 100%;
    }
    .smash-cnt-box {
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
        justify-content: center;
    }
    .smash-cnt-box .blu-border-btn {
        width: 100%;
    }
    .page-ntf button {
        font-size: 14px;
    }
    .title-scr {
        width: 260px;
    }
    .scrore-pg {
        width: calc(100% - 260px);
    }
    .pr-x {
        width: calc(100% - 260px);
    }
    .middle-box {
        width: 180px;
    }
    .graph-detail {
        padding: 0;
    }
    .page-heading {
        margin: 0 0 30px;
    }
    .m-view-none {
        display: none;
    }
}

@media screen and (max-width: 540px) {
    .col-card {
        max-width: 50%;
    }
    .title-scr {
        width: 200px;
    }
    .scrore-pg {
        width: calc(100% - 200px);
    }
    .pr-x {
        width: calc(100% - 200px);
    }
    .box-office-content h3 {
        font-size: 26px;
    }
    .pr-x {
        width: 100%;
    }
}  

.success-body{
    background-color: #218838;
    padding: 20px;
    color: white;
    font-size: 22px;
    font-weight: 400;
}

.warning-body{
    background-color: #e0a800;
    padding: 20px;
    color: white;
    font-size: 22px;
    font-weight: 400;
}

.error-body{
    background-color: #66011b;
    padding: 20px;
    color: white;
    font-size: 22px;
    font-weight: 400;
}

.loader-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F8F8F8AD;
    z-index:3
  }
  .loader{
    left: 46%;
    top: 40%;
    z-index: 10000;
    position: absolute;
  }

  .p-t-20{
      padding-top: 20px;
  }


  
.form-group {
    display: block;
    margin-bottom: 15px;
  }
  
  .form-group input { padding: 0;  height: initial;width: initial; margin-bottom: 0; display: none;   cursor: pointer;  }  
  .form-group label {  position: relative;   cursor: pointer; }  
  .form-group label:before {
    content:'';
    -webkit-appearance: none;
    background-color:transparent;
    border: 2px solid #FFF5F8;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }

  .form-group input:checked + label:before{background-color:#00D8C3; border-color:#00D8C3}  
  td .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 11px;
    width: 6px;
    height: 12px;
    border: solid #FFF5F8;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

td .form-group label:before {height:20px; width:20px; padding:0;}
.form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 9px;
    width: 6px;
    height: 12px;
    border: solid #FFF5F8;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.forgot .check span:before {padding: 0;width: 15px;height: 15px;}
.forgot .check input:checked + span:after { top: 7px;  left: 4px;  width: 6px;  height: 10px;}

  .cust-dayplayer { color: #03dac6 !important;
    font-size: 17px;
    font-weight: 500;} 
 
    .form-group-sign .check span:before {
        content: '';
        -webkit-appearance: none;
        background-color: transparent;
        border: 1px solid #FFF5F8;
        box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
        /* padding: 10px; */
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
        border-radius: 1px;
        height:15px;
        width:15px;
    }

    .form-group-sign .check input:checked + span:after {
        content: '';
        display: block;
        position: absolute;
        top:7px;
        left: 5px;
        width: 5px;
        height: 9px;
        border: solid #4d0014;
        border-width: 0 2px 2px 0;
        transform: rotate( 
    45deg
     );
    }

    .form-group-sign  .check input:checked + span:before {
        content: '';
        border: #20fce5;
        background: #20fce5;
    }

    .form-group-sign .check span:before{padding:0;}
    .form-group-sign .check span {position: relative; cursor: pointer; display: flex;    align-items: center; } 
    .form-group-sign input {display: none;}
    .login-check .check {display: flex;}


    .card-box {
        background: #800022;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;
        flex-wrap: wrap;
      }

    .profileImage {
     width: 45px;
    height: 45px;
    border-radius: 100%;
    background: #02B09F;
    font-size: 30px;
    font-weight: 400;
    color: rgb(182, 14, 14);
    text-align: center;
    margin: 20px 0;
    border: white 1px solid;
    display: inline-block;
    }




    .cardProfileLetterImage {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        background: #02B09F;
        font-size: 40px;
        font-weight: 400;
        color: rgb(182, 14, 14);
        text-align: center;
        display: inline-block;

    }

    .card-Letter {
    height: 70px;
    width: 70px;
    border-radius: 100%;
    padding: 3px;
    border: 2px solid white
    }