.mainModel{
    height: 99%;
    padding-top: 52px;
}


.pTag{
  font-size: 16px;  
}
.draftANewCallSheet{
    display: flex;
    justify-content: center;
    align-items: center;
}