.left-side {width: 390px;}
.left-side button {width: 100%; text-align: left; background: #CC0136; display: flex; align-items: center; margin-bottom:10px;}
.left-side button .icn {color:#03DAC6;margin-right:10px;width: 40px;height: 40px;background: #4D0014;display: flex;border-radius: 100%;align-items: center; justify-content: center;}


.setting-box {max-width:90%; margin: auto;}
.accordion-item {background: transparent;border: 0; margin-bottom: 20px;}
.accordion-header button {font-weight:300; color:#FFF5F8;font-size: 24px;padding: 10px 0; background:transparent; outline: none !important; flex-wrap:wrap;}
.accordion-button:not(.collapsed) {color: #FFF5F8; background-color: transparent; box-shadow: none !important;}
.right-side {width: calc(100% - 420px);background: #1A0007;border-radius:10px; padding:30px;}
.accordion-button:focus {z-index: 3; outline: none; box-shadow: none !important; border: 0 !important;}
.accordion-header button span {display: inline-block;color: #FF668E; font-size: 14px; margin-top: 5px;}
.accordion-button::after {transform: translateY(-50%);position: absolute;right: 0;top: 50%;content: "\f0d7";font: normal normal normal 14px/1 FontAwesome;background-image: none;}
.accordion {margin: 25px 0 0;}
.accordion-item .accordion-header:hover {position: relative; z-index: 99;}
.accordion-header:hover:after {top:0;z-index:-1;content:'';background:#4D0014 !important;height: 100%;position: absolute;left: -10px;right: -10px;border-radius: 10px;}
.accordion-body {padding: 20px 0;}
.accordion-button:not(.collapsed)::after{background-image: none !important;}
.accdn-icn span {margin-right: 10px;}
.accdn-icn .sms-hvr{display: none;}
.accordion-item  :hover .sms-hvr{display: block;}

.ntf-stg-row {display: flex; align-items: center; justify-content: space-between;border-bottom: 1px solid #4D0014; padding: 20px 10px;}
.on-off-btn {display: flex; align-items: center;}
.on-off-btn span {color: #FFCCDA; margin-right:15px;}

.on-btn .form-switch .form-check-input { outline: none; background-color: transparent;  border: 1px solid #03DAC6;  height: 30px !important;  width: 60px !important;}
.on-btn .form-switch .form-check-input { width: 2em; background-image: url(../../assets/images/on.png) !important;  }
.accdn-icn{width:100%; display: flex; align-items: center;}

.off-btn .form-switch .form-check-input {outline: none; background-color: transparent;  border: 1px solid #4D0014;  height: 30px !important;  width: 60px !important;}
.off-btn .form-switch .form-check-input { width: 2em; background-image: url(../../assets/images/off.png) !important;  }
.sml-text {font-size: 16px; margin-top:16px;}
.border-nav {margin-top: 0;}

@media screen and (max-width: 1024px){
.left-side {width: 300px; padding: 15px 10px;}
.right-side {width: calc(100% - 300px);}
.right-side {width: calc(100% - 300px);}
.accordion-header button {font-size: 18px; }
.accordion-body p {font-size: 16px;}
.border-nav {margin-top:15px;}
}
    
    @media screen and (max-width:767px){
    .setting-box {flex-wrap: wrap;}  
    .left-side {width: 100%; }
    .right-side {width: 100%;}
    .user-detail{margin-top:20px;}
    .right-side {margin-top: 30px;}
    .right-side-box { padding:20px;}  
    }