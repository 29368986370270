.header .logo img {height: 70px; margin-right:50px;}
.left-header {display: flex; align-items: center;}
.header nav { background: transparent !important;}
.header {display: flex; justify-content: space-between;}
.header .nav .nav-link {color: #FFF5F8;font-size: 18px;font-weight: 300;padding:35px 20px;}
.header .nav .nav-link:focus, .header .nav .nav-link:hover { color: #FFF5F8; background: #CC0136 !important}
.header .navbar {padding: 0;}
.nav-item .active{color: #FFF5F8; background: #CC0136 !important;}
.header button.bln-btn {background: #4D0014; border: none; border-radius: 20px;height: 40px; padding: 0 15px;color: #FF668E; font-size: 14px;}
.header-notification.dropdown button { background: #4D0014; border: 0; height:50px; width:50px; border-radius: 100%;color: #00d8c3; position: relative;}
.header-notification.dropdown button:after {display:none;}
.header-notification.dropdown span.badge {background: #CC0136 !important;border-radius: 100%;padding: 5px 7px;position: absolute; right: -12px;top: 0;}
.header-right {display: flex; align-items: center;}
.header-right button {margin-left: 30px;}
.header-right .header-notification .dropdown-menu.show { display: block; background: #420011; width:400px; padding-top: 15px;}
.header-right .ntf-heading {display: flex; align-items: center;  justify-content: space-between; margin-bottom: 20px; padding-left:15px; padding-right: 15px;}
.header-right .ntf-heading h3 {font-size: 18px;color: #00d8c3;}
.header-right .ntf-row h6 {color: #FF668E;font-size: 18px; padding-left:10px; margin-bottom:10px;}
.header-right .ntf-item-left p {color: #FFF5F8; font-size:14px; margin: 0 0 8px;}
.header-right .dot span { display: inline-block; height:5px; width:5px; background: #00d8c3; margin-left: 5px; border-radius: 100%;}
.header-right .ntf-item-left span {color: #FFCCDA; font-size: 12px;}
.header-right .ntf-row {padding: 0 0 0 5px;}
.header-right .ntf-border {border-bottom: 1px solid #FF668E;margin-bottom: 25px;padding: 0 0 20px 5px;}
.header-right .ntf-item {padding: 10px;border-radius: 5px;}
.header-right .ntf-item:hover {background: #37010f;}
.header-right .header-user .dropdown-menu.show { display: block; background: #420011; width:400px; padding: 15px;}
.header-right  .user-img img {height: 80px; width: 80px; border-radius: 100%; object-fit: cover;}
.header-right  .user-img  {height: 88px; width: 85px; border-radius: 100%;border:1px solid #ccc}
.header-right .user-img {margin:auto; height:90px;width: 90px;border-radius: 100%;border: 2px solid #CC0136;display: flex;align-items: center;justify-content: center;}
.user-img-row {display: flex;justify-content: center; margin-bottom: 20px;}
.user-detail {width: 100%;text-align: center;}
.user-detail h4 {color: #20FCE5;font-size:22px;}
.user-blnc {color: #FF668E;font-size: 18px; margin: 6px 0;}
.user-detail .see-profile {color: #FFCCDA; font-size: 12px;}
.user-detail-box {padding: 20px;border-radius: 10px;}
.user-detail-box:hover{background:#37010f;}
.link-user .link-user-icn { margin-right:15px;height: 35px; width: 35px; background: #61011a; display: flex; justify-content: center;align-items: center; border-radius: 100%; color: #20FCE5;font-size: 23px;}
.link-user a {display: flex;align-items: center; justify-content: space-between; color: #FFCCDA;}
.link-user .link-user a {color: #FFCCDA;}
.link-user span.link-user-arrow {color: #20FCE5;}
header .dropdown-item:focus,  header .dropdown-item:hover { background-color: transparent !important; color: #FFCCDA;}.header .logo img {height: 70px; margin-right:50px;}
.left-header {display: flex; align-items: center;}
.header nav { background: transparent !important;}
.amdin-header  {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #4D0014 !important;
    padding-bottom: 10px;
    padding-top: 10px;
}
.header .nav .nav-link {color: #FFF5F8;font-size: 16px;font-weight: 300;padding:35px 10px;}

.header .nav .nav-link:focus, .header .nav .nav-link:hover { color: #fff; background: #CC0136 !important}
.header .navbar {padding: 0;}
.header button.bln-btn {background: #4D0014; border: none; border-radius: 20px;height: 40px; padding: 0 15px;color: #FF668E; font-size: 14px;}
.header-notification.dropdown button { background: #4D0014; border: 0; height:50px; width:50px; border-radius: 100%;color: #00d8c3; position: relative;}
.header-notification.dropdown button:after {display:none;}
.header-notification.dropdown span.badge {background: #CC0136 !important;border-radius: 100%;padding: 5px 7px;position: absolute; right: -12px;top: 0;}
.header-user.dropdown button {color: #00d8c3;position: relative;padding: 0;display: flex; width: 69px;align-items: center; background: transparent;border: 0;}
.avtar-border {position: absolute;height: 47px;width: 47px;transition: transform 250ms;}
.header-user.dropdown .avtar {background: #4D0014;border: 0;height: 50px;width: 50px;border-radius: 100%;color: #00d8c3;position: relative;display: flex;align-items: center;justify-content: center;}
.avtar:hover .avtar-border {transform: rotate(60deg);  }
.avar-image {width: 40px;height: 40px;border-radius: 100%;}

.header-right {display: flex; align-items: center;}
.header-right button {margin-left: 30px;}
.header-right .header-notification .dropdown-menu.show {display: block;background: #420011;width: 400px; padding-top: 15px; inset: unset !important;transform: inherit !important;right: 0 !important;}
.header-right .ntf-heading {display: flex; align-items: center;  justify-content: space-between; margin-bottom: 20px; padding-left:15px; padding-right: 15px;}
.header-right .ntf-heading h3 {font-size: 18px;color: #00d8c3;}
.header-right .ntf-row h6 {color: #FF668E;font-size: 18px; padding-left:10px; margin-bottom:10px;}
.header-right .ntf-item-left p {color: #FFF5F8; font-size:14px; margin: 0 0 8px;}
.header-right .dot span { display: inline-block; height:5px; width:5px; background: #00d8c3; margin-left: 5px; border-radius: 100%;}
.header-right .ntf-item-left span {color: #FFCCDA; font-size: 12px;}
.header-right .ntf-row {padding: 0 0 0 5px;}
.header-right .ntf-border {border-bottom: 1px solid #FF668E;margin-bottom: 25px;padding: 0 0 20px 5px;}
.header-right .ntf-item {padding: 10px;border-radius: 5px;}
.header-right .ntf-item:hover {background: #37010f;}
.header-right .header-user .dropdown-menu.show { display: block; background: #420011; width:400px; padding: 15px;}
.header-right  .user-img img {height: 80px; width: 80px; border-radius: 100%; object-fit: cover;}
.header-right  .user-img  {height: 88px; width: 85px; border-radius: 100%;border:1px solid #ccc}
.header-right .user-img {margin:auto; height:90px;width: 90px;border-radius: 100%;border: 2px solid #CC0136;display: flex;align-items: center;justify-content: center;}
.user-img-row {display: flex;justify-content: center; margin-bottom: 20px;}
.user-detail {width: 100%;text-align: center;}
.user-detail h4 {color: #20FCE5;font-size:22px;}
.user-blnc {color: #FF668E;font-size: 18px; margin: 6px 0;}
.user-detail .see-profile {color: #FFCCDA; font-size: 12px;}
.user-detail-box {padding: 20px;border-radius: 10px;}
.user-detail-box:hover{background:#37010f;}
.link-user .link-user-icn { margin-right:15px;height: 35px; width: 35px; background: #61011a; display: flex; justify-content: center;align-items: center; border-radius: 100%; color: #20FCE5;font-size: 23px;}
.link-user a {display: flex;align-items: center; justify-content: space-between; color: #FFCCDA;}
.link-user .link-user a {color: #dbd4d6;}
.link-user span.link-user-arrow {color: #20FCE5;}
header .dropdown-item:focus,  header .dropdown-item:hover { background-color: transparent !important; color: #FFCCDA;}
.mobile-nav{display:none;}
.nav-item .active {background: #CC0136 !important;color: #dbd4d6;}
.nav-item a {color: #FFF5F8;}

.th-sticky { position: sticky;  top: 0; background: #0a0204;  padding-bottom: 5px;}

footer{margin-top:0;}

@media screen and (max-width: 1480px) {
.header .logo img {height: 60px !important; margin-right:30px;}
}

@media screen and (max-width: 1199px){

.header .nav .nav-link{padding: 35px 10px;}
.header .logo img { height:45px; margin-right: 30px;}


}


@media screen and (max-width: 1024px){
.header-notification { display: none;    }
.header button.bln-btn{display: none;}
.amdin-header .mobile-nav {display: none !important;}
.left-header .nav {display: none;}
.header {flex-wrap: wrap;}
.header .nav .nav-link {padding: 8px 20px; border-radius: 5px;}
.banner .banner-img { margin-top: 0;}
.banner .page-ntf{display: none;}
.mobile-nav .nav-item a {padding: 8px 20px; border-radius: 4px; color:#FFF5F8}
.mobile-nav .nav { width: 100%;  display: flex;  padding: 0 15px;  justify-content: center;}
.header {display: flex; justify-content: space-between;     padding-bottom:10px;}

}


@media screen and (max-width:767px){
.mobile-nav .nav-item a {padding: 8px 6px;font-size: 12px;}
.mobile-nav .nav {justify-content: space-between;}
}
