.complete-row { display: flex; justify-content: space-between;}
.complete-row  .up-contest-content { display: flex;  justify-content: space-between;  flex: 0 auto;}
.completed-table {margin-top:40px;}
.heading-wh { color: #FFF5F8; margin-bottom: 30px; font-style: italic;}

.background-box { background: #1A0007;  border-radius: 10px;  padding: 20px; margin-top:40px;}
.table-main td:first-child{text-align: center;}
.table-main th:first-child {text-align:center;}

@media screen and (max-width: 1024px){

.complete-row{flex-wrap: wrap;}
.left-heading { width: 100%; justify-content: center; display: flex;  flex-wrap: wrap; margin-bottom:30px;}
.left-heading h2{width:100%; text-align: center;}
.complete-row .up-contest-content{flex:100%;}
.completed-table{margin-top:0;}
}