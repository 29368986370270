.pupup-btn-row a {
    color: #FFCCDA !important;
    text-decoration: underline !important;
    margin-top: 8px;
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 14px;
}

.fixColumn{
    position: sticky;
    z-index: 10;
    left: 0;
    background-color: #800022 !important;
    
    
}

.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    left: 0;
}
.paginationButton{
    font-size: 30px;
    margin: 10px;
    padding: 2px 25px;
    color: white;
    background-color: #790020;
}


