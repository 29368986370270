.smash-box.live-content button { color: #FFF5F8; border-color: #FFF5F8;}
.smash-box.live-content :hover button {background:#FFF5F8 ; color:#CC0136}
.contest-content-right {display: flex; justify-content: end;}
.contest-content-right .up-cont-box { text-align: center; padding-right: 50px;}

.contest-row {display: flex; justify-content: space-between;}
.contest-left {width:59%; padding-right:15px; padding-top: 15px;}
.contest-right {width:42%;background: #1A0007;border-left: 1px solid #4D0014; padding:15px;}
td img.user { width:25px; height:25px;  border-radius: 100%; display: inline-block; max-width:25px; object-fit: cover;}
img.anchor-img { height: 15px;} 
.cust-dayplayer { color: #03dac6 !important;
    font-size: 17px;
    font-weight: 500;} 
.plus-button {color: #b5d6d3;}
.minus-button {color: #FF3269;}
.cmp-logo {height: 14px;}
/* .table-main { min-height: 50vh;} */
.table-main table tr th span:hover i { display: inline; position: absolute; right: 7px; font-size: 10px;  top: 38%;}
.table-main th {padding: 15px 0;}
.table-main td:first-child .form-group {width: 35px;}
.button-tb { justify-content: end;}
.callsheet-img-row {display: flex; align-items: center;}
.callsheet-img-row img {margin-right:20px;}
.upcoming-contest-row {align-items: center;}
.btntie button {width: 100%;padding: 0 10px;}
.tiebreaker-row {display: flex;align-items: center;justify-content: space-between; margin: 15px 0 0;}

.tiebreaker-row .btntie {width: 100px;}
.tiebreaker-row  .tiebreaker-cnt {width: calc(100% - 120px);}
.tiebreaker-box {padding: 0 40px 20px; border-bottom: 1px solid #CC0136;margin: 0 -15px;}

.trm-cnd{padding: 20px 40px;}
.trm-cnd .right-text {color:#00dc00; position: relative;}
.trm-cnd p {margin: 0 0 10px;color: #F90044;}
.right-text:before {content: '';width: 15px;height: 7px;border-left: 2px solid #00dc00;border-bottom: 2px solid #00dc00;position: absolute;left: -24px;top: 8px;transform: rotate(320deg);}
.callsheet-submit button {width: 100%;}
.callsheet-submit a {color: #FFCCDA !important;text-decoration: underline !important;margin-top: 8px;display: inline-block;width: 100%;text-align: center;font-size: 14px;}
.player-left .contest-content-right {justify-content: space-between;}
.player-left.row {margin-top: 20px;}
.search-col {margin-top: 40px;margin-bottom: 40px;}
.callsheet-btn-row button {margin-right: 20px;}

.callsheet-btn-row a{color:#FF668E !important;}

.contest-table-lv a.plus-button i {  color: #20FCE5;}

@media screen and (max-width: 1480px) {
    .table-main table tr th span {  font-size: 14px; padding: 5px 15px;}
    .table-main td {border: 0;  font-size: 14px;}
    .button-tb a {padding: 0 10px; font-size: 14px;}
    .table-main table tr th span i{font-size: 12px;}
    .upcoming-contest-row h2{font-size:28px;}
    .callsheet-img-row img {margin-right: 10px;}
    }    
    
    @media screen and (max-width: 1199px){
    
    .search-col [class*=col-] {width: 100%; }
    .button-tb { justify-content: center; width: 100%; margin-top: 20px !important;}
    .upcoming-contest-row h2 { font-size: 20px;}
    .callsheet-img-row img {width: 35px;}
    .callsheet-img-row .ctn-cls p {font-size: 14px;}
    }
    
    @media screen and (max-width: 1024px){
    .up-cont-box p { font-size: 14px;}
    .upcoming-contest-row p {font-size: 14px;}
    .contest-content-right .up-cont-box {text-align: center;padding: 0 5px;}
    .up-cont-box span {font-size: 14px;} 
    .tiebreaker-cnt p { font-size: 14px;}  
    .trm-cnd p {font-size: 14px;}
    .callsheet-btn-row button { margin-right: 10px; font-size: 16px;}
    .callsheet-submit button { font-size: 16px;}
    .btntie button {font-size: 14px;}
    .mcl-row h2 {text-align: left;    }
    .mcl-row-1 h2 {margin-bottom:20px !important; text-align: left !important;}
    }
    
    @media screen and (max-width: 991px)
    {
    .contest-row{flex-wrap: wrap;}
    .contest-left { width: 100%; padding-right:0;}
    .contest-right {width: 100%;border-left: 0; padding: 15px; margin-top: 20px;}
    .upcoming-contest-row.row p {text-align: center; margin-bottom:0;}
    .upcoming-contest-row h2 { font-size: 20px; padding-right: 0; margin-bottom:0;}
    .contest-content-right {flex-wrap: wrap;}
    .page-ntf {margin-bottom: 30px;}
    .callsheet-btn-row button { font-size: 13px;}
    }


    .txt-score{background: #800022;}

    