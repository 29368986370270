.popup-profile { display: flex; justify-content: space-between; align-items: center;}
.profile-box { display: flex; align-items: center;}
.profile-img img {height: 140px;width: 140px; border-radius: 100%;  object-fit: cover;}
.profile-img {width: 150px;height: 150px;border-radius: 100%; overflow: hidden;border: 1px solid #F90044; display: flex; justify-content: center; align-items: center; margin-right:20px;}
.profile-detail h4 {color: #FFF5F8; font-weight: 300;}
.profile-detail .time {font-size: 16px; color:#FF99B4}
.profile-detail p {font-size: 16px;}
.profile-detail .salary {color: #00D8C3;}
.score-history {border-top: 1px solid #4D0014;margin: 20px -30px 0; padding: 20px 30px 0;}
.score-date-tble table th{position: sticky;height: 45px;top: 0px;background: #0a0204;}
.score-draft-heading {display: flex;justify-content: space-between; align-items: center;}
.score-draft-heading p {color: #03DAC6;}
.score-draft-heading h4 {color: #FFCCDA; font-weight: 100;}
.score-draft-heading {margin-top: 20px;}
.score-date-tble .table-main {height:320px;}
.up-cont-box .delete-btn {margin-left: 10px;}

.banner-adv {width: 450px;}
.movie-type-row {border-bottom: 1px solid #800022;margin-bottom:40px;margin-left: -30px;margin-right: -30px;padding: 0 30px 20px;}
.right-advent {width: calc(100% - 450px);padding: 30px;}
.movie-type-row {display: flex; flex-wrap: wrap; justify-content: space-between;}
.movie-type-box {padding: 0 10px;text-align: center;}
.movie-type-box h5 {font-weight: 100; margin-bottom: 15px;}
.user-card .col-card {max-width: 33.3%;}
.adventure-popup .modal-body {padding: 0;overflow: hidden;border-radius: 15px;}
.model-2 {display: none !important;}
.right-advent h4 {margin: 0 0 20px; font-weight: 300;}
.adv-pop-row {display: flex;flex-wrap: wrap;}
.banner-adv {width: 450px;background-size: cover !important;}
.banner-adv-box h2 {font-size:48px;text-align: center;text-shadow: 0 2px 3px #000000ce;color: #FFF5F8;font-weight: 400; margin-bottom:40px;}
.banner-adv-box {padding:20px;background-image: linear-gradient(0deg, #450012, #200009a8, transparent); height: 100%;display: flex;  align-items: end;}

.user-card button {font-size: 14px;height: 45px; margin-top: 10px; width:100%;}
.user-card .heading-icn {margin: 5px 0 0;}
.user-card .right-advent h4 {margin: 5px 0 0;}
.overview {margin-bottom: 30px;}
.right-advent .scroll-table {padding: 0 5px;max-height: 400px;}
.smash-card-row.user-card {margin-top: 0;}
.rt-critics{text-align: center; margin-bottom:20px;}
.rt-critics h5 {font-weight: 300;text-align: center;width: 100%; margin-bottom: 15px;}

@media screen and (max-width: 1024px){

.banner-adv {width: 380px;}
.right-advent {width: calc(100% - 380px); padding: 20px;}
.rt-critics { width: 50%;}
.banner-adv-box h2 {font-size: 30px;}
.movie-type-box h5 {font-size: 14px;}
.movie-type-row {margin-bottom:20px; margin-left: -20px; margin-right: -20px;  padding: 0 30px 20px;}
.right-advent h4 {margin: 0 0 10px;}
.overview p {font-size: 14px;}
.user-card .col-card { max-width: 100%;}
.movie-type-box p {font-size: 14px;}
}

@media screen and (max-width: 767px){
.profile-img {width: 110px;  height: 110px;}
.profile-img img {height: 100px; width: 100px;}
.score-history {padding: 0 30px 0;}
.score-draft-heading {margin-bottom: 20px;}
.popup-profile {justify-content: center; align-items: center; flex-wrap: wrap;}
.profile-box { width: 100%; display: flex; justify-content: center; margin-bottom: 30px;}
.banner-adv {width: 100%;}
.right-advent { width: 100%; padding: 20px;}
.user-card .col-card {max-width: 33.3%;}
.card-name a { font-size: 14px;}
}