.complete-row { display: flex; justify-content: space-between;}
.complete-row  .up-contest-content { display: flex;  justify-content: space-between;  flex: 0 50%;}
.completed-table {margin-top:40px;}
.button-tb { justify-content: end;  display: inline-flex;}


@media screen and (max-width: 1024px){

.complete-row{flex-wrap: wrap;}
.left-heading { width: 100%; justify-content: center; display: flex;  flex-wrap: wrap; margin-bottom:30px;}
.left-heading h2{width:100%; text-align: center;}
.complete-row .up-contest-content{flex:100%;}
.completed-table{margin-top:0;}
}





/* .content {
    padding:0px !important;
    min-height: 100vh;
} */