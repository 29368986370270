.btn-success:active, .show>.btn-success.dropdown-toggle { background: #33000D !important; border-radius: 2px;}
.pbar-no p { text-align: center;  color: #FFCCDA;   font-size: 28px;}
.graph-detail button {font-size: 18px;}
.comp-score-box button:hover {background: #33000D;border: 0;box-shadow: none; transition: 0.3s; border-radius:20px; }
.comp-score-box .dropdown-item {background: transparent;color: #20FCE5;text-align: center !important;border-top: 1px solid #420011;padding: 8px 0;width: 100% !important;font-size: 18px;}

.main-popup .modal-body {padding-top: 0;}
.bg-dark-main .complete-row {margin-bottom: 20px;}

@media screen and (max-width: 1480){
.score .right-box {width: calc(67% - 15px);  padding: 15px 15px;  }
.title-row.heading-scr-bar h4 { font-size: 20px;  font-weight: 100;}
.title-scr p { font-size: 14px;}
.box-office-content h3{font-size:48px;}
.pbar-no p{font-size:20px ;}
.score .box-row{flex-wrap: wrap;}
.react-sweet-progress { justify-content: flex-start}
}

.numberBtn {
    background: transparent !important;  
     display: inline-block;
     text-align: center;
     width: auto;
     height: auto;
     cursor: pointer;
     text-decoration: underline;
    
    }


.number-Btn:hover {
    cursor: hand;
}


.number-Btn-Text {
    position: relative;
    display: flex;
    justify-content: center
}

.number-Btn-Text span {

white-space: nowrap;
}

/* .number-Btn-Text:hover span {
    padding: 5px;
    display: block;
    position: absolute;
    background: #CC0136;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 20px;
    bottom: -50px;
    color: #FFF5F8;
    margin-left: 20px;
} */

/* .number-Btn-Text span:before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    background: #CC0136;;
    top: -4px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
} */

.legend{
   height: 20px;
   width: 50px;
   display: inline;
   background-color: yellow !important;
}