.left-side {width: 390px; position: sticky; top:320px;}
.content-support .left-side button {width: 100%; text-align: left;  display: flex; align-items: center; background: transparent;}
.content-support .left-side button.active {background: #CC0136; }
.left-side button .icn {color:#03DAC6;margin-right:10px;width: 40px;height: 40px;background: #4D0014;display: flex;border-radius: 100%;align-items: center; justify-content: center;}
.content-support  .left-side h3 {margin-bottom: 30px;}

.setting-box {max-width:90%; margin: auto;}
.accordion-item {background: transparent;border: 0; margin-bottom: 20px;}
.accordion-header button {font-weight:300; color:#FFF5F8;font-size: 24px;padding: 10px 0; background:transparent; outline: none !important; flex-wrap:wrap;}
.accordion-button:not(.collapsed) {color: #FFF5F8; background-color: transparent; box-shadow: none !important;}
.right-side {width: calc(100% - 420px);}
.accordion-sectiion{background: #1A0007;border-radius:10px; padding:5px 30px 5px;}
.content-support .right-side { background: transparent !important; padding: 0;  border-radius: 0; margin-bottom: 30px;}
.accordion-button:focus {z-index: 3; outline: none; box-shadow: none !important; border: 0 !important;}
.accordion-header button span {display: inline-block;color: #FF668E; font-size: 14px; margin-top: 5px;}
.accordion-button::after {transform: translateY(-50%);position: absolute;right: 0;top: 50%;content: "\f0d7";font: normal normal normal 14px/1 FontAwesome;background-image: none;}
.accordion-item .accordion-header:hover {position: relative; z-index: 99;}
.accordion-header:hover:after {top:0;z-index:-1;content:'';background:#4D0014 !important;height: 100%;position: absolute;left: -10px;right: -10px;border-radius: 10px;}
.accordion-body {padding: 20px 0;}
.accordion-button:not(.collapsed)::after{background-image: none !important;}
.accdn-icn span {margin-right: 10px;}
.accdn-icn .sms-hvr{display: none;}
.accordion-item  :hover .sms-hvr{display: block;}
.accordion-header button {padding: 10px 15px 10px 0 !important;}

.ntf-stg-row {display: flex; align-items: center; justify-content: space-between;border-bottom: 1px solid #4D0014; padding: 20px 10px;}
.on-off-btn {display: flex; align-items: center;}
.on-off-btn span {color: #FFCCDA; margin-right:15px;}
.accdn-icn{width:100%; display: flex; align-items: center;}
.sml-text {font-size: 16px; margin-top:16px;}

.accordion-body p { color: #FF668E; margin-bottom:20px;}
.accordion-body p:last-child{margin-bottom: 0;}
.support-box {flex-wrap: wrap;}
.Banner {position:sticky;top: -60px; z-index:999; height: 355px; width: 100%; background-size: cover !important;background-repeat: no-repeat;background-position: center center !important;border-radius: 20px;margin-bottom: 40px;}
.search-btn input {background: #800022 !important;}
.search-btn {background: #800022;}
.banner-content h1 {font-size: 65px;font-weight: 100; text-align: center; margin-bottom:20px; color:#FFCCDA;}
.banner-content {width: 550px;position: absolute; left: 80px; top: 50%; transform: translateY(-50%);}
.search-btn input::placeholder {color:#FFF5F8 !important;}
.search-btn span i {color: #00D8C3;}
.support-btn-section h3 {font-size: 44px;text-align: center;padding:70px 0 30px; color:#FFCCDA}
.support-btn-section {text-align: center;}
.setting-box .left-side h3 {margin-bottom: 40px;}

@media screen and (max-width: 1199px){
.banner-content h1 {font-size:40px;text-align: left;}
.accordion-header button {font-size: 20px;}
.setting-box .left-side h3 {margin-bottom: 30px !important; font-size: 24px;}
.left-side button {font-size: 16px;}
.support-btn-section h3 {font-size: 30px !important;text-align: center; padding: 30px 0 30px !important;}
}

@media screen and (max-width: 1024px){
.left-side {position: static; width: 180px; height: auto;}  
.Banner {position: static;margin-top: 15px; }
.right-side {width: calc(100% - 210px);}
.accordion-body p {font-size: 14px;}

.Banner { height: 280px; }

}

@media screen and (max-width: 767px){
.left-side button { font-size: 14px;}
.accordion-header button span {font-size: 12px;}
.accordion-header button {font-size: 16px;}
.banner-content {width: 80%; margin: auto;  left: 0;  right: 0;}
.banner-content h1 {font-size: 28px; }
.content-support .support-btn-section h3 {font-size: 24px; padding: 20px 0 30px;}
}