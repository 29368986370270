.smash-box.live-content button { color: #FFF5F8; border-color: #FFF5F8;}
.smash-box.live-content :hover button {background:#FFF5F8 ; color:#CC0136}
.contest-content-right {display: flex; justify-content: end;}
.contest-content-right .up-cont-box { text-align: center; padding-right: 50px;}

.search-col {margin-top: 40px;margin-bottom: 40px;}
.callsheet-btn-row button {margin-right: 20px;}

.dropdown-menu.show {background: #33000d; width: 100%;}
.all-trans {margin-left: 20px;}


.all-trans .dropdown-item{color:#FFF5F8;}
.th-sticky {position: sticky; top: 0; background: #0a0204; padding: 10px 0 5px; z-index: 9;}

@media screen and (max-width: 1480px) {
.table-main table tr th span {  font-size: 16px; padding: 5px 10px;}
.table-main td {border: 0;  font-size: 14px;}
}    

@media screen and (max-width: 1024px){
.page-ntf{margin-top:10px;}

}

@media screen and (max-width: 991px)
{
.drop-slc {margin-top: 20px; justify-content: center !important;}
}

@media screen and (max-width: 767px)
{
.contest-content-right{flex-wrap: wrap;}
.search-col .search-btn.input-group {margin-bottom: 20px;}
.drop-slc {margin-top: 20px; justify-content: center !important;}
.drop-slc {flex-wrap: wrap;}
.drop-slc .button-tb {width: 100%; justify-content: center; margin-bottom: 20px;}
.all-trans { margin-left: 0; width: 100%;}
.all-trans button {width: 100%;}
.drop-slc {margin-top: 20px; justify-content: center !important;}
}