.left-side {width: 390px; padding: 30px 15px;}
.setting-box {max-width:90%; margin: auto;}
.right-side {width: calc(100% - 420px);}

.right-side-box {background: #1A0007;border-radius: 10px;padding: 30px;}

.name-row h4 {font-size: 24px; font-weight: 300; color: #FFF5F8;}
.name-row p {font-size: 14px; color: #FF668E;}
.name-row {margin-bottom: 50px;}
.profile-detail h3 {margin-bottom: 40px;}
.edit-btn button {background: #800022;color: #20FCE5;border: 0;border-radius: 100%;font-size: 18px;height: 40px;width: 40px;}
.profile-user {height: 220px;width: 220px;display: flex; align-items: center; justify-content: center; position: relative; margin: auto;}
.profile-user-border{height: 220px; width: 220px;background: url(../../assets/images/profile-border.png); position: absolute;transition: transform 250ms;}
.profile-user img {height: 190px; width: 190px; border-radius: 100%; object-fit: cover;}
.profile-user:hover .profile-user-border {transform: rotate(123deg); }
.user-detail {width: 100%; text-align: center; margin-top: 40px;}
.user-detail p {font-size: 20px;color: #FF99B4; margin: 15px 0 25px;}
.edit-img button {background: #4d0014a6; border: 0; outline: 0; width: 40px !important; height: 40px; border-radius: 100%; position: absolute;bottom: 0;right: 16px;}
.edit-img {position: absolute;bottom: 20px; right: 0; width: 100%;}
.dropdown-toggle::after{display: none;}
.edit-img  btn-success:hover{background:#4d0014a6 !important; border: none !important; outline: none;}
.edit-img .dropdown-menu.show {    padding: 15px 10px; background: #4D0014;width: 100% !important;left: 0 !important; right: 0 !important; margin: 0 auto !important;transform: none !important;top: 30px !important;}
.edit-img .dropdown-menu.show a {font-size: 14px;text-decoration: none;display: flex;align-items: center;margin-bottom:20px;color: #fff;}
.edit-img .dropdown-menu.show a span {display: flex; width: 30px; height: 30px; background: #800022; border-radius: 100%; align-items: center; justify-content: center; color: #02D2BF;margin-right: 10px;}
.change-pwd-box {margin-top: 30px;}
.change-pwd-box h3{margin-bottom:40px;}
.change-pwd-box a{font-size: 14px; color: #FF668E;}r
.change-btn-row {display: flex; flex-wrap: wrap; justify-content: center;}
.change-btn-bx {width: 100%;text-align: center;}

.forgot-pwd .form-grp{width:50%;}
.row-input .form-control, .row-input .form-select {color:#FFF5F8 !important; padding-right: 25px;background:transparent;box-shadow: none !important; border: 0; border-bottom: 1px solid  #FF668E; border-radius: 0; padding-left:0;}
.row-input {position:relative;}
.eye-pwd {position: absolute; right: 85px; top: 45%;transform: translateY(-50%);}
.form-control:focus{background-color: transparent !important; border: 0; border-bottom: 1px solid  #FF668E; border-radius: 0; }
.forgot-pwd label {font-size:16px; color: #FF668E; margin: 0 0 5px;}
.sbm-row {display: flex; justify-content: end; align-items: center; margin-top: 40px;}
.sbm-row a{margin-right:20px;}
.change-pwd-box input::placeholder{  color: #FF668E; background-color: transparent !important;}
.change-pwd-box input::selection{  color: #FF668E;background-color: transparent !important;}
.profile-stn {border-top: 1px solid #f90044; padding: 25px 15px 0; margin: 10px -15px 0;}
.profile{display: flex; flex-wrap: wrap;}
.add-form h4 { margin-bottom: 20px;}
/* .add-form form {height: 70vh; overflow-y: auto; width: 100%;  overflow-x: hidden;} */

.form-box {background: #662a3a61;border: 0 !important;padding: 15px 15px 24px !important;border-radius: 20px;}
option{color:black}

@media screen and (max-width: 1024px){
.name-row h4 {font-size: 18px;}
.left-side {width: 300px; padding: 15px 10px;}
.right-side {width: calc(100% - 300px);}
.change-btn-bx {width: 100%;  text-align: left;}
.change-btn-row {justify-content: left;}
}

@media screen and  (max-width: 991px) {
.page-ntf {text-align: center; }
}

@media screen and (max-width:767px){
.setting-box {flex-wrap: wrap;}  
.left-side {width: 100%; }
.right-side {width: 100%;}
.user-detail{margin-top:20px;}
.right-side {margin-top: 30px;}
.name-row {margin-bottom: 30px;}
.name-row h4 {font-size:16px;}
.edit-btn button {font-size: 12px; height: 30px;  width: 30px;}
.edit-btn {justify-content: flex-end;display:flex;}
.right-side-box { padding:20px;}
.forgot-pwd .form-grp { width: 100%;}
.profile .right-side-box {padding: 0;}
.profile  .right-side { padding: 15px;}
}