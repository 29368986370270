
.main-div {display: flex;}
.left {background-color: #0a0204;display: flex; width:60%;}
.content { display: flex; justify-content: center;  padding: 26px 70px;  min-height: 100vh; flex-wrap: wrap;box-sizing: border-box;}
.left .content .middle {max-width:550px;align-self: center;margin:100px 0 70px;}
 .left .content .bottom { align-self: center; margin-bottom: 30px; width:100%;}
.left .content .bottom  h3 {font-weight: 300;color: #00d8c3;font-size: 40px; margin-bottom: 30px; text-align: center;}
.top { width: 100%;}
.links{ display: flex; justify-content: space-between; padding: 0 30px;  align-items: center;}
.links button {border: none;background-color: inherit; outline: none; font-size: 18px; cursor: pointer; display: inline-block; color: #fff5f8;}

.right {width:40%; background-color: #4d0014; position: relative;}
.right  .right-box{padding: 25px;}
.right .close {justify-content: flex-end;display: flex; position: absolute;top: 25px;right: 25px;}
.right .cross{ display: flex; justify-content: flex-end;}
.right .form-content{padding: 0 50px;margin-bottom: 10px;}
.right .form-content .tabs { display: flex; justify-content: flex-start; margin-top:15px; margin-bottom:50px;}
.right .tabs button {border:none;outline: none;background-color: inherit;margin-right: 50px;font-size: 18px;cursor: pointer; display: inline-block;}
.right .tabs button  {color: #fff5f8;}
.right  .tabs button.active  {color: #20fce5; border-bottom: 3px solid #ff0144; padding-bottom:10px;}
.right .heading h1 {font-size: 44px;font-weight: 300;color: #fff5f8;padding-right: 30px;margin-bottom: 40px; line-height:36px;}
.right .heading h2 {font-size: 25px;font-weight: 300;color: #fff5f8;padding-right: 30px;margin-bottom: 40px;}


.form-g{ display: flex;flex-direction: column; margin-bottom: 30px;}
.form-g label span {color: #ff668e;font-weight: 400;font-size: 16px;}
.form-g label {display: inline-block; max-width: 100%;  margin-bottom: 5px;}
.form-g input { border: none;  outline: none;  border-bottom: 1px solid #ff668e; background-color: inherit;  color: white; padding-bottom: 5px;}
.form label {display: inline-block; max-width: 100%; margin-bottom: 5px; margin-left:0; }
.form .forgot label {font-size: 16px; color: white;  }
.form .forgot button { border: none; outline: none; background-color: inherit; font-weight:300; font-size: 16px; color: #ff668e;}
.form .forgot button span {color: #ff668e;}
.form .forgot {display: flex;justify-content: space-between; margin-bottom: 30px;}
.form button.login {font-weight: 400;letter-spacing: 2px;text-transform:uppercase;background-color: #00d8c3;width: 100%;height:50px;border: none; outline: none; font-size: 16px; margin-bottom: 8px; border-radius: 8px; color:#fff;}
.form button.login:hover {background-color: #ff0144;}
.form .new-player button { border: none; outline: none; background-color: inherit; font-size: 16px;color: #ff668e;}
.new-player button:first-child span {color: #FF668E;}
.form .new-player {display:flex; justify-content: center; margin-top:10px;}
.form .new-player button.signup-go{border: none; outline: none; background-color: inherit;  font-size: 16px; color: #fff5f8;}
.form .social-login-button{display: flex;flex-direction: column;margin-top: 50px;}
.form .social-login-button button {height: 50px;border: 1px solid #fff5f8;border-radius: 8px; margin-bottom: 15px; background-color: inherit;font-size: 16px;color: white;}
.form .social-login-button button img {margin-right: 15px;}
.form .or {display: flex;justify-content: center; margin: 50px -75px;color: #fff;font-size: 18px;position: relative;}
.form .or p {padding: 0;margin: 0;background: #4d0014;position: relative;z-index: 1; padding: 0 5px;}
.form .or:after {content: '';position: absolute;width: 100%; height: 1px; background: #ef7a99;top: 50%;}
.form .or span{display:none;}
.form .forgot input {margin-right: 10px;}

.right .terms {color: #fff5f8;font-weight: 400; font-size: 12px;}
.right .terms span {color: #20fce5; text-decoration: underline;}

.forgot-pwd .right {display: flex;justify-content: center;align-items: center;}
.logo-mobile{display: none;}
.mobile-view-footer{display: none;}
.forgot-pwd .form-content {padding: 0 80px;}
.mobile-banner{display: none;}

.confirm-password-box .heading h1 {font-size: 52px; margin-bottom: 10px; padding-right:0;}
.confirm-password-box p {font-size: 24px; margin-bottom: 30px; margin-top: 25px;}
.confirm-password-box {text-align: center;}
.blue-btn.back-to-lobby {width: 90%;}


.birthday-row {display: flex;align-items: center;justify-content: space-between;margin: 0 -10px;}
.birthday-date {padding: 0 10px;width: 33.3%;}
.birthday-date select {   outline: none;color:#fff5f8;border: 0;background: transparent;border-bottom: 1px solid #ff668e;border-radius: 0; padding: 5px 0 10px;width: 100%;text-align: left;}
.birthday-date label {width: 100%; padding: 0;}
.er {font-size: 14px; margin-top: 5px;}

.gender-row {display: flex;align-items: center;justify-content: space-between;margin: 0 -10px;}
.gender-col {padding: 0 10px;width: 33.3%;}
.gender-col .form-group {color: #fff5f8;display: flex;}

.form-content.reset-form h2 {font-size: 22px; color: #FFF5F8;}
.form-content.reset-form h1 {margin-bottom: 15px;}
.reset-pwd .right { display: flex; align-items: center;}
.reset-form .form {margin-top: 20px;}

@media screen and (max-width:1480px){
.confirm-password-box.reset-link-box .heading h1 { font-size: 40px; text-align: center;}
.right .heading h1 {font-size:25px;}
}


@media screen and (max-width: 1199px){
.right {width:50%;}
.left {width:50%;}
.links{flex-wrap: wrap;justify-content: center;}
.right .form-content{padding: 0;}
.form .or {margin: 50px -25px;}
.left .content .bottom h3{font-size:30px;}
.left .content .middle{margin: 50px 0;}
.content{padding:20px;}
.links button, .links img{margin-bottom:20px;}
.forgot-pwd .form-content {padding: 0 50px;}
}    

@media screen and (max-width: 767px){

.left{display: none;}
.right {width:100%;}
.right .close{position: static;justify-content: space-between;}
.logo-mobile{display: block; max-height:45px;}
.form .or span,.form .or p {display: inline-flex; font-size:14px;}
.right .tabs button{margin-right: 30px;}
#google {background: #FE2C25;}
#facebook{background:#0574E7;}
#twitter{background: #1EA1F1;}
.form .social-login-button button{border: 0;padding: 0 15px;flex-direction: inherit; justify-content:center; font-size:12px;}
.form .social-login-button button span {display: none;}
.form .social-login-button button img { margin-right: 5px;}
.form .social-login-button { display: flex; flex-direction: inherit; margin-top:30px; justify-content: space-between;}
.mobile-view-footer {display: flex; margin: 20px -15px 0; background: #32000D;padding: 10px 0;justify-content: center;}
.links button, .links img{margin-bottom: 0; font-size:12px;}
.links{padding: 0;}
.links img{height:15px;}
.right .right-box {padding: 25px 15px 0;}
.form .forgot label{font-size:14px;}
.form .forgot button{font-size:14px;}
.right .form-content{margin-bottom:0;}
.right .terms{display: none;}
.form .or {margin: 30px -15px;}
.form .social-login-button button{display: flex; align-items: center; height: 40px; width:30%;}
.form button.login{height:40px;margin-top: 20px;}
.form-g{margin-bottom:20px;}
.form label{margin-bottom:0;}
.form .social-login-button button img {height: 15px;}
.sign-up-terms{display: block !important;}
.right .form-content .tabs{margin-bottom:40px;}
.forgot-pwd .form-content {padding: 0;}
.forgot-pwd .right {min-height: 100vh;}
.mobile-banner {display: block !important;max-width: 80%;margin: 50px auto;}
.confirm-password-box .bottom.mobile-view-footer {position: absolute; bottom: 0; width: 100%; left: 0; right: 0; margin: 0;}
.blue-btn.back-to-lobby {width:100%;}
.confirm-password-right .close {position: absolute; width: 100%; left: 0;  padding: 0 15px;}
.confirm-password-box .heading h1 {font-size: 36px;}
.confirm-password-box p {font-size: 20px;}
.confirm-password-box.reset-link-box .heading h1 {font-size: 28px; text-align: center;}
}